import { Keyboard } from "@capacitor/keyboard";
import { isDefined } from "@clipboard-health/util-ts";
import { InputChangeEventDetail, isPlatform } from "@ionic/core";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
  IonSpinner,
  IonToolbar,
} from "@ionic/react";
import { Agent } from "@lib/interface";
import { OnboardingRouterPath } from "@src/app/routing/constant/onboardingRoute";
import { useSession } from "@src/app/store/helperHooks";
import { useToast } from "@src/appV2/lib";
import { OnboardingErrors } from "@src/constants/loginErrorConstants";
import { addDataToIdentity } from "@src/lib/analytics";
import { getFirebaseSingleton } from "@src/lib/firebase/src";
import { getPhoneWithoutCode } from "@src/lib/utils";
import { FirebaseUpdateError } from "@src/utils/AppErrors/firebaseCustomErrors";
import { reVerifyPhoneRequestOTP, reloadAgentProfile } from "@store/session";
import { isEmpty } from "lodash";
import { FC, FormEvent, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import Alert from "./Alert";
import { updateAgentData, verifyEmail } from "./api";
import { OnBoardingComponentProps } from "./model";
import { StyledIonPage } from "./style";
import { ErrorAlert } from "./types";
import { ONBOARDING_SEGMENT_EVENT_NAMES } from "../constants/ONBOARDING_SEGMENT_EVENT_NAMES";
import { ONBOARDING_STAGES } from "../constants/ONBOARDING_STAGES";
import { logOnboardingError } from "../util/logging";
import { fireOnboardingSegmentEvent } from "../util/segment";

const AgentEmail: FC<OnBoardingComponentProps> = ({ agent, nextStagePath }) => {
  const { showErrorToast } = useToast();
  const { profile } = useSession();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>(agent?.email ?? "");
  const [errorAlert, setErrorAlert] = useState<ErrorAlert>({ hasError: false });
  // should merge message and toggle flag into one object, not doing right now to save regression.
  const [errorModalMessage, setErrorModalMessage] = useState<string>("");
  const history = useHistory();
  const dispatch = useDispatch();
  const captchaRef = useRef<HTMLDivElement | null>(null);

  const showErrorAndInitializeRecaptcha = (errorMessage: string) => {
    showErrorToast(errorMessage);
    getFirebaseSingleton().initRecaptchaVerifier("recaptcha-container", captchaRef.current);
  };

  const loginWithPhone = async () => {
    const phone = getPhoneWithoutCode(profile?.phone as string);
    const verified = (await dispatch(reVerifyPhoneRequestOTP(phone))) as unknown as boolean;
    if (verified) {
      history.push({
        pathname: OnboardingRouterPath.ONBOARDING_REVERIFY,
        search: `?phoneNumber=${phone}`,
      });
    }
  };

  const onEmailChange = (event: CustomEvent<InputChangeEventDetail>) => {
    const mail = event.detail!.value!;
    setEmail(mail);
  };

  const updateAgentEmail = async (previousAgent: Agent | undefined, newEmail: string) => {
    const previousAgentWasDefined = isDefined(previousAgent);

    if (!previousAgentWasDefined) {
      // When initially setting email, backend is still searching for a firebase user
      // based on email (even though it only has phone number set for now)
      // so we have to update this property before calling the endpoint
      await getFirebaseSingleton().updateCurrentUserEmail(newEmail);
    }

    const { agent: updatedAgent } = await updateAgentData({
      stage: ONBOARDING_STAGES.EMAIL,
      onboardingFlags: previousAgent?.onboardingFlags, // must include this
      email: newEmail,
    });

    await addDataToIdentity(updatedAgent.userId, {
      onboardingFlags: previousAgent?.onboardingFlags, // must include this
      email: updatedAgent.email,
      phone: updatedAgent.phone,
      type: "AGENT",
    });

    fireOnboardingSegmentEvent(ONBOARDING_SEGMENT_EVENT_NAMES.ENTERED_EMAIL, {
      hcpId: updatedAgent.userId,
      email: updatedAgent.email,
    });

    if (previousAgentWasDefined) {
      // If that was a user with existing email, we cannot set firebase property before calling
      // the backend because it will cause our backend lookup for user to fail. Example: user with email A wants
      // to update their email to B, but because firebase property is already updated so backend search
      // for the user in our database by email B (before it's actually set, so it fails)
      await getFirebaseSingleton().updateCurrentUserEmail(newEmail);
    }

    dispatch(reloadAgentProfile());
  };

  const onNext = async (event?: FormEvent | MouseEvent): Promise<void> => {
    event?.preventDefault();
    if (isPlatform("capacitor")) {
      await Keyboard.hide();
    }

    setErrorAlert({ hasError: false });

    if (!email) {
      showErrorAndInitializeRecaptcha("Please enter your email address");
      return;
    }

    if (!nextStagePath) {
      throw new Error("Missing nextStagePath is required in agentEmail onboarding step");
    }

    if (agent?.email === email) {
      history.push(nextStagePath);
      return;
    }

    setIsLoading(true);

    try {
      const isEmailValid = await verifyEmail(email);
      if (!isEmailValid) {
        showErrorAndInitializeRecaptcha("Please enter a valid email address.");
        setIsLoading(false);
        return;
      }

      await updateAgentEmail(agent, email);

      history.push(nextStagePath);
    } catch (err) {
      logOnboardingError(ONBOARDING_STAGES.EMAIL, (err as Error).message);
      if (err?.response?.status === 422) {
        err.message =
          "We could not verify your email address. Please try again or contact support for manual verification.";
        setErrorModalMessage(err.message);
      } else if (
        err instanceof FirebaseUpdateError &&
        err.errorCode === OnboardingErrors.REQUIRES_RECENT_LOGIN
      ) {
        setTimeout(() => {
          loginWithPhone();
        }, 200);
        return;
      } else if (err instanceof FirebaseUpdateError) {
        setErrorModalMessage(err.message);
      } else {
        setErrorModalMessage(err?.response?.text);
      }
      setErrorAlert({
        hasError: true,
        reason: err?.message || err?.response?.text,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const closeErrorModal = () => {
    setErrorAlert({ hasError: false });
  };

  return (
    <StyledIonPage className="onboarding-page">
      <IonHeader no-border className="onboarding-header">
        <IonToolbar className="onboarding-toolbar">
          <IonButtons slot="start">
            <IonBackButton text="" defaultHref="/home/agentSignupInfo1" mode="ios" color="dark" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-padding">
        <Alert
          isOpen={errorAlert.hasError}
          reason={errorAlert.reason}
          closeAlert={closeErrorModal}
          message={errorModalMessage}
        />

        <div className="signup-content content-layout">
          <div className="form-container">
            <form onSubmit={onNext}>
              <IonRow>
                <IonCol sizeMd="8" offsetMd="2" offsetLg="4" sizeLg="4">
                  <div className="form-heading">
                    <h4>What's your email?</h4>
                  </div>

                  <div>
                    <IonLabel className="form-label">Email Address</IonLabel>
                    <IonItem lines="none" className="form-item-wrapper">
                      <IonInput
                        name="email"
                        className="form-input"
                        type="email"
                        data-testid="emailInput"
                        placeholder="Email address"
                        onIonChange={onEmailChange}
                        value={email}
                        disabled={isLoading}
                        autofocus
                        required
                      />
                    </IonItem>
                  </div>
                </IonCol>
              </IonRow>
            </form>
          </div>

          <div className="signupform-footer footer-container">
            <IonButton
              expand="block"
              size="large"
              class="ion-margin-top ion-margin-bottom continue-button"
              disabled={isLoading || isEmpty(email)}
              onClick={onNext}
            >
              Continue
              {isLoading && <IonSpinner slot="end" class="ion-margin-start" name="lines" />}
            </IonButton>
            <div ref={captchaRef}></div>
          </div>
        </div>
      </IonContent>
    </StyledIonPage>
  );
};

export { AgentEmail };
