import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonLabel,
  IonRow,
  IonSpinner,
  IonToolbar,
} from "@ionic/react";
import { useSession } from "@src/app/store/helperHooks";
import { useToast } from "@src/appV2/lib";
import { isEmpty } from "lodash";
import { FC, FormEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import timezoneLookup from "tz-lookup";

import Alert from "./Alert";
import { showSSNPage, updateAgentData } from "./api";
import { AddressPageData, OnBoardingComponentProps } from "./model";
import { StyledIonPage } from "./style";
import { ErrorAlert } from "./types";
import { LocationEdit } from "../../address/locationEdit";
import { Place } from "../../address/types";
import { OnboardingRouterPath } from "../../routing/constant/onboardingRoute";
import { updateAgent } from "../../store/session";
import { ONBOARDING_SEGMENT_EVENT_NAMES } from "../constants/ONBOARDING_SEGMENT_EVENT_NAMES";
import { ONBOARDING_STAGES } from "../constants/ONBOARDING_STAGES";
import { logOnboardingError } from "../util/logging";
import { getOigState } from "../util/oig";
import { fireOnboardingSegmentEvent } from "../util/segment";

async function getNextStagePath(): Promise<string | undefined> {
  const oig = getOigState();
  if (oig?.dobRequired) {
    return OnboardingRouterPath.ONBOARDING_DOB;
  }

  const { showSSNFlag } = await showSSNPage();
  if (showSSNFlag) {
    return OnboardingRouterPath.ONBOARDING_SSN;
  }
}

const AgentAddress: FC<OnBoardingComponentProps> = ({ nextStagePath }) => {
  const { showErrorToast } = useToast();
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [updatingData, setUpdatingData] = useState<boolean>(false);
  const [errorAlert, setErrorAlert] = useState<ErrorAlert>({ hasError: false });

  const [addressData, setAddressData] = useState<AddressPageData | Partial<AddressPageData>>({});

  const { agent } = useSession();

  const history = useHistory();
  const dispatch = useDispatch();

  const onNext = async (event?: FormEvent | MouseEvent): Promise<void> => {
    event && event.preventDefault();
    setErrorAlert({ hasError: false });
    try {
      setUpdatingData(true);
      await updateAgentData({
        stage: ONBOARDING_STAGES.ADDRESS,
        address: addressData.address,
        location: addressData.location,
        tmz: timezoneLookup(addressData?.location?.lat, addressData?.location?.lng),
      });
      dispatch(
        updateAgent({
          ...agent,
          address: addressData.address,
          tmz: timezoneLookup(addressData?.location?.lat, addressData?.location?.lng),
        })
      );
      fireOnboardingSegmentEvent(ONBOARDING_SEGMENT_EVENT_NAMES.ENTERED_ADDRESS, {
        hcpId: agent?.userId as string,
        hcpEventValue: addressData?.address?.formatted,
        email: agent?.email,
      });

      const next = (await getNextStagePath()) ?? nextStagePath;
      history.push(String(next));
    } catch (error) {
      if (!error.response || !error.response.text || error.response.status >= 500) {
        logOnboardingError(ONBOARDING_STAGES.ADDRESS, (error as Error).message, agent?.userId);
        setErrorAlert({ hasError: true, reason: error?.message });
        return;
      }
      showErrorToast("Unexpected error occurred while saving address");
    } finally {
      setUpdatingData(false);
    }
  };

  useEffect(() => {
    // important. we need to setLoadingData true here
    // in case the user navigates back and forth through the screens

    // loadingData forces a re-render of the LoadingEdit component
    // when our state in this component changes
    setLoadingData(true);
    if (agent) {
      const { geoLocation, address } = agent;
      const lat = geoLocation?.coordinates?.[1];
      const lng = geoLocation?.coordinates?.[0];
      if (!isEmpty(address)) {
        setAddressData({
          address,
          location: lat && lng ? { lat, lng } : undefined,
          searchLocation: address?.formatted ?? "",
        });
      }
    }
    setLoadingData(false);
  }, [agent]);

  const onPlaceChange = (place: Place) => {
    if (isEmpty(place.location) || isEmpty(place.address)) {
      return;
    }

    setAddressData({
      address: place.address,
      location: place.location,
      searchLocation: place.address.formatted ?? "",
    });
  };

  const closeErrorModal = () => {
    setErrorAlert({ hasError: false });
  };

  return (
    <StyledIonPage className="onboarding-page">
      <IonHeader no-border className="onboarding-header">
        <IonToolbar className="onboarding-toolbar">
          <IonButtons slot="start">
            <IonBackButton text="" defaultHref="/home/agentEmail" mode="ios" color="dark" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-padding">
        <Alert
          isOpen={errorAlert.hasError}
          reason={errorAlert.reason}
          closeAlert={closeErrorModal}
        />

        <div className="signup-content content-layout">
          <div className="form-container">
            <form onSubmit={onNext}>
              <IonRow>
                <IonCol sizeMd="8" offsetMd="2" offsetLg="4" sizeLg="4">
                  <div className="form-heading">
                    <h4>Where do you live?</h4>
                  </div>

                  <div>
                    <IonLabel className="form-label">Home Address</IonLabel>
                    {!loadingData && (
                      <LocationEdit
                        searchLocation={addressData.searchLocation ?? ""}
                        onPlaceChange={onPlaceChange}
                        includeLine1Input
                        initAddress={addressData}
                      />
                    )}
                  </div>
                </IonCol>
              </IonRow>
            </form>
          </div>

          <div className="signupform-footer footer-container">
            <IonButton
              expand="block"
              size="large"
              class="ion-margin-top ion-margin-bottom continue-button"
              disabled={updatingData || !addressData.address || !addressData.address.formatted}
              onClick={onNext}
            >
              Continue
              {updatingData && <IonSpinner slot="end" class="ion-margin-start" name="lines" />}
            </IonButton>
          </div>
        </div>
      </IonContent>
    </StyledIonPage>
  );
};

export { AgentAddress };
